import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesKilo: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Kilo guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_kilo.webp"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Kilo guide & review</h1>
          <h2>A guide & review for Kilo in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>27/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Kilo" />
        <StaticImage
          src="../../../images/nikke/generic/banner_kilo.webp"
          alt="Kilo"
        />
        <p>
          Obtain a total of 4x SSR Nikke Kilo during the 1.5 Anniversary event
          period via various missions.
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="kilo" enablePopover />
        </div>
        <p>
          Gundam x Nikke Collab! Syuen has cooked yet again! Missilis' new
          release comes in contact with Crown's Kingdom with her all-useful
          mecha, T.A.L.O.S, in tow. As we see Kilo suffer and grow through an
          inferiority complex, she comes to realize her real potential (which..
          is apparently in PvP!). Last time we had a free Nikke was Snow White:
          Innocent Days, and she was good for early progression. Will Kilo prove
          herself to be another good Free unit? Or will she be just like her
          claim: "Useless without T.A.L.O.S"? Let's figure out with our review
          below.
        </p>
        <SectionHeader title="Summary" />
        <h5>Can you give us a brief introduction?</h5>
        <p>
          Kilo comes with a big box called T.A.L.O.S that blocks our field of
          view and prevents us from watching what the raptures are trying to do.
          Now moving on the serious part, Kilo herself is one of the best AoE
          units available for PvP in current PvP meta. While she has some
          serious drawbacks, her potential in that game mode is not to be
          underestimated. With every con she has, there is a pro that shows her
          prowess! Not only that, Kilo is expected to take over Missilis Tower!
          We believe that the Ark's Hero (and our Hero of course), Laplace, will
          be getting replaced by Kilo for a ton of stages, especially those that
          require AoE wave clearing. We severely lacked a good AoE unit for
          Missilis Tower, and Kilo fills that gap.
        </p>
        <h5>Should I get this unit's dupes from the selector?</h5>
        <p>
          YES! Go Kilo all the way for all 3 dupe selectors! Do not even look at
          Rei if you haven't broken through the level 160 wall yet since Kilo
          can be your MLB SSR who allows you to break the wall and fulfill the 5
          MLB units requirement! Rei is entirely a collector's item, and serves
          only one purpose: finishing the Nikkepedia. Kilo on the other hand, is
          really good for PvP! Dupes are extremely appreciated for PvP. Not only
          that, Kilo will also serve as a vital component not only to Crown's
          kingdom, but also to Missilis Tower!
        </p>
        <SectionHeader title="Kit Analysis & Highlights" />
        <p>
          Kilo has a very simple and non confusing Kit. Half of her kit is
          useless most of the time so it gets easier to explain it as well.
        </p>
        <h5>Nano Coating</h5>
        <blockquote>
          <p> ■ Activates when entering battle. Affects self.</p>
          <p>
            Nano Coating: Creates a Shield equal to 21.12% of the caster's final
            Max HP continuously.
          </p>
          <p>
            ■ Activates when using Burst Skill. Affects self if not in Nano
            Coating status.
          </p>
          <p>
            Nano Coating: Creates a Shield equal to 21.12% of the caster's final
            Max HP continuously.
          </p>
        </blockquote>
        <p>This skill will check the status of Kilo at 2 timings:</p>
        <ul>
          <li>
            When entering the battle, Kilo is always equipped with a thick
            shield that lasts till it is destroyed.
          </li>
          <li>
            When she uses her burst, if Kilo doesn't have shield, it will create
            another shield for her.
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            If Kilo's shield isn't destroyed before she burst, this skill does
            nothing.
          </p>
        </Alert>
        <h5>Self-Cure</h5>
        <blockquote>
          <p>
            ■ Activates when performing Normal Attack for 200 time(s) in Nano
            Coating status. Affects self.
          </p>
          <p>Recovery Shield HP equal to 2.85% caster's final Max HP.</p>
          <p>
            ■ Activates when using Burst Skill. Affects self if not in Nano
            Coating status.
          </p>
          <p>
            Effect changes according to the caster's status. Previous effects
            trigger repeatedly.
          </p>
          <p>Once: Next Shield's HP ▲ 17.75% continuously.</p>
          <p>Twice: Next Shield's HP ▲ 26.66% continuously.</p>
          <p>Three times: Next Shield's HP ▲ 35.53% continuously.</p>
        </blockquote>
        <ul>
          <li>
            Although named "Self" cure, it actually cures her "shield".
            Should've just named it "Shield-cure" instead.
          </li>
          <li>
            The number can be translated into "Every 3.33s (assuming Kilo shoots
            unlimited ammo), she heals 13.5% of her shield". It's actually an
            okay number. This value will be reduced due to the 2nd part of the
            skill.
          </li>
          <li>
            If Kilo's shield FROM S1 (this is very important! It must be the
            shield from S1 and not from Tia/Crown buffing) is broken before she
            uses her burst, the newly created Shield from S1 will have more HP.
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            Same as S1, if her shield is intact before she burst, the 2nd part
            of this skill does nothing.
          </p>
        </Alert>
        <h5>Assign Priority</h5>
        <blockquote>
          <p>■ Activates when in Nano Coating status. Affects all enemies.</p>
          <p>
            Deals damage equal to 1150.84% of the ATK, which is calculated from
            5% of final Max HP.
          </p>
          <p>■ Activates when not in Nano Coating status. Affects self.</p>
          <p>Max HP ▲ 48% for 20 sec.</p>
        </blockquote>
        <p>
          This is actually the very neat part of her strength. This burst has
          effect depends on the status of Kilo's S1 shield at the time burst is
          used.
        </p>
        <ul>
          <li>
            When the shield is destroyed, it grants Kilo 48% max HP (which also
            heals). This effect is simple, and considered very weak because
            beside healing Kilo, it does literally nothing else, just like her
            S1 and S2 when the shield is intact.
          </li>
          <li>
            But when her shield remains, she deals 5% of her max HP multiplies
            with the burst modifier as damage onto all enemies.{' '}
            <strong>This skill DOES NOT scale on any attack buff</strong>, so
            pairing any ATK buffer like Liter/xAnne on her is a very bad idea!
          </li>
          <li>
            On a side note, Kilo scales well on Max HP buff like Noise, Mast.
            Pretty much 2B but free right?
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            Same as all B3 nukers, Kilo burst won't be affected by Full Burst
            bonus, or any buffs that apply when Full Burst starts (like Maxwell
            S1 or Crown S1).
          </p>
        </Alert>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Tier List & Analysis" />
        <h5>
          Campaign (LD) - <strong className="s">S</strong> | Campaign (HD) -{' '}
          <strong className="a">A</strong>
        </h5>
        <p>
          Kilo is good in campaign due to her instant screen clear ability. But,
          she is just... good, nothing amazing or meta in today's game. She
          works kind-of like Harran, which is a good Nikke to compare her to.
          Not much to be said about her personal damage since it isn't amazing
          outside of the AoE screen clear. While Kilo does not benefit from ATK
          buffers, which are crucial for High Deficit Campaign, she works well
          with Attack Damage buffers like Tia/Crown.
        </p>
        <p>
          Tribe Tower: The main use for PvE will be in Missilis tower where she
          can act as an AoE screen clear, something which Missilis Tower
          severely lacked. Hence, she can easily replace Laplace in the meta
          Tribe Tower team! Kilo is expected to perform really well in Missilis
          Tower.
        </p>
        <h5>
          Boss (Solo) - <strong className="c">C</strong> | Boss (Adds) -{' '}
          <strong className="a">A</strong>
        </h5>
        <p>
          Kilo doesn't have impressive damage numbers so she will not see much
          use for Union or Solo Raids. However, she may be used to bypass the
          Elemental Shield Mechanic that some bosses annoy us with in Solo
          Raids. Since there are not a lot of usable Fire units, Kilo can fit in
          possibly to help us out. Some bosses like Mother Whale and Material H
          (solo raid) have hit count mobs, where Kilo, being an MG, can kill
          them. With her AoE clear being somewhat useful, she can prove to be
          useful in Bosses with Additional Raptures.
        </p>
        <h5>
          PVP - <strong className="ss">SS</strong>
        </h5>
        <p>
          Well, if you thought she was doing fine in PvE, she's doing more than
          just fine in PvP. Kilo is the real surprise of this event. Not only is
          she useful for Crown's Kingdom, but she is definitely useful for PvP.
        </p>
        <p>
          Just take a look at this video from a community member on Discord:
          <strong>rroaar</strong> (Kilo Skills: 1/1/10):
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="EvBB08U2DZ8" />
          </Col>
        </Row>
        <p>
          As we've said before, her AoE works like Harran's. But that wasn't the
          whole truth. It's actually better than Harran's by a decent amount. In
          fact, it's even better than Maid Privaty's nuke! Not only that, but
          she benefits from a different set of buffs than normal Nikkes! She is
          not affected by the normal ATK buffs at all from e.g. from RedHood B1.
          But, Kilo does work with Damage taken debuffs from Blanc, or Attack
          Damage Buffs from Tia, who can improve Kilo's DPS substantially!
        </p>
        <Alert variant="primary">
          <p>
            Kilo scales the best with Max HP buffs! That's right, Noise can buff
            Kilo! And not only can she buff, if both Blanc and Noise burst level
            are the same, Noise provides a higher damage buff to Kilo!
          </p>
        </Alert>
        <p>
          From what the math dictates, at low sync lvl PvP (before lvl200),
          players will be able to one shot all of their opponents with either
          none, or at best one buffer. At high sync PvP (i.e., around lvl400),
          one buffer (e.g Noise) is enough to kill off an Attacker unit, while
          Supporter Nikkes are left with a minute amount of HP. With 2 buffers
          (e.g Noise + Blanc), Kilo is able to wipe off the enemy team with her
          trusty T.A.L.O.S!
        </p>
        <p>
          Kilo has some advantages because of her defender status, which allows
          her to tank more damage, and also hide from certain skills which
          target "Highest ATK unit", such as Rosanna skill 1, or Power's Single
          Target Nuke. But, this can also cause trouble and lead her to become a
          target of nukes such as 2B's, which target Highest HP units..
        </p>
        <p>
          While being an MG has it's drawbacks in PvP, in the right team, Kilo
          can absolute cook her opponents with ease! Her AoE is strong, and in
          fast 2RL/2.5RL team, Kilo is a force to be reckoned with. Kilo should
          fear only two opponents in PvP, which unfortunately define the meta.
          Those are Scarlet and Jackal. Otherwise, she should do perfectly fine.
          Unless, of course, the nightmare of AoE nukes: Noah, Bay, or Noise
          have something to say about it with their AoE Block abilities, which
          can prove to be troublesome for Kilo to handle in certain teams...
          Regardless, in Kilo, we have a decent scarlet substitute!
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          As a Defender, gear and OL are not really important to her (because
          eventually, all gears will contribute too little compared to stat gain
          per level, and OL %Def sucks). You can OL her for some CP padding if
          you like, and to improve her HP stats. Wait these lines sound exactly
          like Bay!
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 1~4</strong>
          </li>
          <ul>
            <li>
              Her scaling is actually better than average. But the effect is
              just terrible, and, considering she's just an Early Unit to help
              new players having smoother experience, we highly recommend NOT
              investing too much onto her. Only invest for CP padding.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 1~4</strong>
          </li>
          <ul>
            <li>
              This skill is even worse than S1, so yeah, just level up it a bit
              for CP padding.
            </li>
          </ul>
          <li>
            <strong>Burst: 7-10</strong>
          </li>
          <ul>
            <li>
              Burst is her only damage tool, and it wipes very hard. Not to
              mention, the scaling per level of this skill is also higher than
              average, so you can safely level up this skill high, and even max
              if you care about PVP - a reliable, constant source of gem income.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Suggested Progression Path</strong>
        </p>
        <div className="nikke-skills-tips">
          <div className="nikke-skills-row header">
            <div className="column character">&nbsp;</div>
            <div className="column suggestions pve">PVE Investment</div>
            <div className="column suggestions pvp">PVP Investment</div>
            <div className="column priority">Priority</div>
          </div>
          <div className="nikke-skills-row data">
            <NikkeSkill char="kilo" />
          </div>
        </div>
        <br /> <br />
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_8_sm.png"
          alt="Guide"
        />
        <p>
          Vigor! Her burst scales on HP, her shield scales on HP, there's no
          reason you don't use Vigor (HP cube) on her.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1: Missilis Tribe Tower Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="kilo" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Kilo fits in easily as a meta unit for Missilis tribe tower. We
          believe that she will be used over Laplace for wave-type stages, but
          Laplace may be able to compete against her Vs Bosses. While she does
          not benefit from any ATK buffs, she benefits from Tia's Attack Damage
          Buff.
        </p>
        <SectionHeader title="PVP Team Compositions" />
        <h5>Team #1: 2RL Kilo Jackal</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="kilo" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Simple team, very strong, but very expensive since it uses a lot of
          good units. Initially this team is 3RL, but with lvl7 Quantum cubes on
          3 of the RL units, or with Jackal feed, this team can burst as fast as
          2RL. Noise buffs Kilo, while the others provide burst gen. Kilo nukes
          the enemy fast.
        </p>
        <h5>Team #2: Double the Buffs, Double the Damage</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="kilo" enablePopover />
            </div>
          </div>
        </div>
        <p>
          3RL team. Double buffed Kilo with Noise and Blanc. This team is meant
          to kill all of the opponents, so if you see a slow enough team without
          Noah/Bay, target them with no worries. Also avoid Scarlet and Jackal,
          especially Scarlet! Any slow team will also be subject to death
          against this team.
        </p>
        <h5>Team #3: T.A.L.O.S the wall!</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="kilo" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
        </div>
        <p>
          3RL team. While Noise still buffs Kilo, you save Jackal here because
          Kilo won't die thanks to Biscuit!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>PVP</p>
          </div>
        </div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    High burst AoE damage. Highly usable for PvP or Wave
                    Clearing!
                  </li>
                  <li>
                    Has a thicc shield that should protect our Kilo from being
                    one-shot.
                  </li>
                  <li>
                    Having Defender stats means she won't be targeted by a lot
                    of skills in PvP, Rosanna's S1 for example.
                  </li>
                  <li>
                    Uses unique buffers like Noise to buff her DPS - great for
                    PvP synergy!
                  </li>
                  <li>
                    Due to defender stats, can tank through unbuffed AoE nukes
                    in high-sync PvP.
                  </li>
                  <li>Kilo is cute!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Takes like 60% of the visual field, even higher on smaller
                    phone screens. The only thing you can see on your screen is
                    T.A.L.O.S.
                  </li>
                  <li>Relies heavily on having the shield to deal damage.</li>
                  <li>
                    As a result of the previous point, she actually hates being
                    focused. If the shield's broken, she deals 0 dmg for the
                    next 40s.
                  </li>
                  <li>
                    Very selfish Nikke: Although wearing a big shield, she has
                    no taunt so she won't protect any teammate.
                  </li>
                  <li>
                    Her shield also doesn't cover all 5 units, only herself.
                  </li>
                  <li>In most situations, her S1 and S2 do nothing.</li>
                  <li>
                    She is a Defender, so her DPS outside of her AoE burst is
                    not worth mentioning at all.
                  </li>
                  <li>
                    MG unit, which means she can be countered in PvP quite
                    effectively by using Scarlet or Jackal.
                  </li>
                  <li>Low Burst gen</li>
                  <li>We can't see Kilo in battle.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesKilo;

export const Head: React.FC = () => (
  <Seo
    title="Kilo guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Kilo in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
